import React from 'react';
import './App.css';

function App() {
  return (
    <div className="app-container">
      <header className="title">
        Kevin Hira
      </header>
      <header className="subtitle">
        Solving Life's Problems One Byte At A Time
      </header>
      <div>
        <p>
          Coming soon...
        </p>
      </div>
    </div>
  );
}

export default App;
